// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import {Application} from "@hotwired/stimulus"
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

require("@rails/ujs").start();
import "@hotwired/turbo-rails"

require("@rails/activestorage").start();
require("channels");
require("highcharts");
require("jquery-slinky");
var moment = require('moment-timezone');

require("select2/dist/js/select2.full");

import '../stylesheets/application'

require('../lifetimely/js/jquery.sticky-kit.min');
require('../lifetimely/js/jquery.toast.min');

require.context('../lifetimely/img', true);

require('bootstrap');
require('bootstrap-slider');
require('daterangepicker');
require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-scroller-bs4');

require('../block_and_unblock_UI');
const turbolinksHelper = require('../turbolinks_helper');

const {tailwind_toast} = require('../tailwind_toast');
window.TailwindToast = tailwind_toast;

window.ToastCustom = require('../toast_custom');

var introJs = require('intro.js/intro');

import Shepherd from 'shepherd.js';

import 'materialize-css/dist/js/materialize'

import {MDCRipple} from '@material/ripple';

var load_chart = require("../report_chart");
var effects = require("../effects");
var usecsv_importer = require("../usecsv_importer");
var product_cost = require("../product_cost");
var lifetime_value = require('../lifetime_value');
var onboarding_form = require('../onboarding_form');
var left_main_menu = require('../left_main_menu');
var scroll_fade_overlay = require('../scroll_fade_overlay');
var income_statement = require('../income_statement');
var lifetime_compare_cohorts = require('../lifetime_compare_cohorts');
var skeleton_helper = require('../skeleton_helper');
var freePlanDatepicker = require('../free_plan_datepicker');

import {get_filters_and_options_data} from "../filters_and_options";

var block = (el) => {
    el.block();
}


function handleInsightsDisplay(insightsHeader) {
    if (insightsHeader === 'true') {
        $('[data-show-when-insights=true]').removeClass('hidden');
        $('[data-show-when-insights=false]').addClass('!hidden');
    }
}

function bindAjaxForInsightsFooterDisplay() {
    $(document).on('ajaxComplete', function (event, xhr, settings) {
        const insightsHeader = xhr.getResponseHeader && xhr.getResponseHeader('X-Insights-Engine');
        handleInsightsDisplay(insightsHeader);
    });
}

function bindTurboLinksForInsightsFooterDisplay() {
    document.addEventListener('turbo:submit-end', function (event) {
        const usingInsights = event?.detail?.fetchResponse?.response?.headers?.get('X-Insights-Engine');
        handleInsightsDisplay(usingInsights);
    });

    document.addEventListener('turbo:frame-render', function (event) {
        const usingInsights = event?.detail?.fetchResponse?.response?.headers?.get('X-Insights-Engine');
        handleInsightsDisplay(usingInsights);
    });
}

// Activate filters and options listeners, etc.
var setUpGlobalEventListeners = () => {
    bindAjaxForInsightsFooterDisplay();
    bindTurboLinksForInsightsFooterDisplay();

    $('.report-block').unblock();

    $('.close-tailwind-modal').click(function () {
        $('.tailwind-modal').addClass("hidden");
        $("#tailwind-modal-backdrop").addClass("hidden");
        $('.tailwind-modal').removeClass("flex");
        $("#tailwind-modal-backdrop").removeClass("flex");
    });

    $(".collapse").on('shown.bs.collapse', function () {
        if ($("#edit-comparison-mertrics").length > 0) {
            $("#benchmarks--sidedrawer-backdrop").addClass("flex");
            $("#benchmarks--sidedrawer-backdrop").removeClass("hidden");
            $(".navbar-main").css("z-index", "0");
        }
    });

    $(".collapse").on('hidden.bs.collapse', function () {
        if ($("#edit-comparison-mertrics").length > 0) {
            $("#benchmarks--sidedrawer-backdrop").removeClass("flex");
            $("#benchmarks--sidedrawer-backdrop").addClass("hidden");
            $(".navbar-main").css("z-index", "1000");
        }
    });

    $(document).on('click', function (e) {
        if ($(e.target).closest('.benchmarks--sidedrawer').length) return;

        $(".benchmarks--sidedrawer").length && $(".benchmarks--sidedrawer.show").collapse("hide")
    });

    $(".benchmarks--sidedrawer .btn-primary").click(function () {
        $(".benchmarks--sidedrawer").collapse('toggle');
    });

    $('#add-new-shop, #add-new-amazon-shop').click(function () {
        $("#add-shop-form button svg").addClass("hidden");
        $("#add-shop-form button").removeAttr("disabled");
        $("#add-shop-form button").removeClass("cursor-wait");
        $('#add-shop-modal').toggleClass("hidden flex");
        $("#tailwind-modal-backdrop").toggleClass("hidden flex");
    });

    $('.store-menu-dropdown').mouseover(function (e) {
        e.stopPropagation();
        $(".store-menu-dropdown .shop-avatar").addClass($(this).data("shop-avatar-bg"));
        $(".store-menu-dropdown .shop-avatar").removeClass("bg-gray-500");
    }).mouseout(function (e) {
        e.stopPropagation();
        $(".store-menu-dropdown .shop-avatar").removeClass($(this).data("shop-avatar-bg"));
        $(".store-menu-dropdown .shop-avatar").addClass("bg-gray-500");
    })


    $(".store-menu-dropdown").click(function (e) {
        $('.store-menu-dropdown').toggleClass("open");
        $('.store-menu-dropdown .caret').toggleClass("!open-caret");
    });

    $(document).on("click", function (event) {
        var $trigger = $(".store-menu-dropdown");
        if ($trigger !== event.target && !$trigger.has(event.target).length) {
            $(".store-menu-dropdown").removeClass("open");
        }
    });

    $('.js-top-banner').click(function () {
        setTimeout(() => {
            $('#left-main-menu').animate({top: "-57px"}, 20)
        }, 380)

        let expirationDate = new Date()
        expirationDate.setDate(expirationDate.getDate() + 1);
        let expiresUTC = expirationDate.toUTCString();
        document.cookie = `top_banner=true; expires=${expiresUTC}; path=/`;
    })

    $('.js-alert-box-container .js-alert-close').click(function () {
        $(this).closest('.js-alert-box-container').fadeOut();
    })

    $("#add-shop-form button").click(function (e) {
        var shop = $("#add-shop-form #shop-domain").val()
        e.preventDefault();
        $("#add-shop-form button svg").removeClass("hidden");
        $(this).addClass("cursor-wait");
        $(this).attr("disabled", "disabled");

        $.ajax({
            type: "POST",
            url: "/shops/check_shop_install",
            data: {
                shop: shop
            },
            success: function (xhr, status, error) {
                const shopIsInstalled = xhr.installed
                if (shopIsInstalled) {

                    $.ajax({
                        type: "POST",
                        url: "/shops/link",
                        data: {
                            shop: shop
                        },
                        success: function (xhr, status, error) {
                            $("#add-shop-form button svg").removeClass("hidden");
                            $("#add-shop-form button").attr("disabled", "disabled");
                            $("#add-shop-form button").addClass("cursor-wait");
                        },
                        error: function (xhr, status, error) {
                            var error_message = JSON.parse(xhr.responseText).error
                            $("#add-shop-form button").removeAttr("disabled");
                            $("#add-shop-form button").removeClass("cursor-wait");
                            $("#add-shop-form button svg").addClass("hidden");
                            ToastCustom.fireAlertToast(error_message);
                        }
                    });

                } else {
                    $('#add-shop-modal').toggleClass("hidden flex");
                    $('#check-shop-install-modal').toggleClass("hidden flex");
                }
            },
            error: function (xhr, status, error) {
                var error_message = JSON.parse(xhr.responseText).error
                $("#add-shop-form button").removeAttr("disabled");
                $("#add-shop-form button").removeClass("cursor-wait");
                $("#add-shop-form button svg").addClass("hidden");
                ToastCustom.fireAlertToast(error_message);
            }
        });
    });

    $('#provider-fees').change(function () {
        if (this.checked)
            $('.provider-fees-content').slideDown('slow');
        else
            $('.provider-fees-content').slideUp('slow');

    });

    $('#terms-of-service').on('change', function () {
        const newTermsCheckbox = document.getElementById('new-terms-of-service')
        if (this.checked && newTermsCheckbox.checked) {
            $("#tailwind-terms-modal #accept-data-processing-agreement").removeClass("disabled")
        } else {
            $("#tailwind-terms-modal #accept-data-processing-agreement").addClass("disabled")
        }
    });

    $('#data_processing_and_terms_of_service, #terms_of_service').on('change', function () {
        if (this.checked) {
            $("#tailwind-terms-modal #accept-data-processing-agreement-xor-terms-of-service").removeClass("disabled")
        } else {
            $("#tailwind-terms-modal #accept-data-processing-agreement-xor-terms-of-service").addClass("disabled")
        }
    });

    $('#accept-data-processing-agreement-xor-terms-of-service').click(function () {
        const newTermsCheckbox = document.getElementById('terms_of_service')
        const dataProcessingAgreementAndNewTermsCheckbox = document.getElementById('data_processing_and_terms_of_service')

        // If checkbox not present then they've already accepted
        const oldTermsAlreadyAccepted = (dataProcessingAgreementAndNewTermsCheckbox == null)

        if (oldTermsAlreadyAccepted && newTermsCheckbox.checked) {
            $.post({
                url: '/shop/accept-new-terms-of-service',
                success: function () {
                    window.location.reload();
                    $("#tailwind-terms-modal").addClass("hidden")
                    $("#tailwind-terms-modal").removeClass("flex")
                    $("#tailwind-terms-modal-backdrop").addClass("hidden")
                    $("#tailwind-terms-modal-backdrop").removeClass("flex")
                }
            })
        } else if (dataProcessingAgreementAndNewTermsCheckbox.checked) {
            $.post({
                url: '/shop/accept-data-processing-agreement-and-new-terms-of-service',
                success: function () {
                    $("#tailwind-terms-modal").addClass("hidden")
                    $("#tailwind-terms-modal").removeClass("flex")
                    $("#tailwind-terms-modal-backdrop").addClass("hidden")
                    $("#tailwind-terms-modal-backdrop").removeClass("flex")
                }
            })
        }
    });

    $('#level_of_details_slider').bootstrapSlider();
    let first_order_value_slider = $('#first_order_value_filter').bootstrapSlider().on('slideStop', UpdateFirstOrderValueInputs);
    let first_order_discount_slider = $('#first_order_discount_filter').bootstrapSlider().on('slideStop', UpdateFirstOrderDiscountInputs);

    function UpdateFirstOrderDiscountInputs(e) {
        let value = $('input[id=first_order_discount_filter]').val();
        value = value.split(",").map(Number)
        $('#first_order_discount_filter_min_value').val(value[0])
        $('#first_order_discount_filter_max_value').val(value[1])
    };

    function UpdateFirstOrderValueInputs(e) {
        let value = $('input[id=first_order_value_filter]').val();
        value = value.split(",").map(Number)
        $('#first_order_value_filter_min_value').val(value[0])
        $('#first_order_value_filter_max_value').val(value[1])
    };

    $('#first_order_value_filter_min_value, #first_order_value_filter_max_value').keyup(function () {
        let minValue = $("#first_order_value_filter_min_value").val()
        let maxValue = $("#first_order_value_filter_max_value").val()
        if (!isNaN(minValue) && !isNaN(maxValue)) {
            let sliderValue = [minValue, maxValue]
            $('input[id=first_order_value_filter]').val(sliderValue);
            first_order_value_slider.bootstrapSlider('setValue', sliderValue.map(Number))
        }
    });

    $('#first_order_discount_filter_min_value, #first_order_discount_filter_max_value').keyup(function () {
        let minValue = $("#first_order_discount_filter_min_value").val();
        let maxValue = $("#first_order_discount_filter_max_value").val();
        if (!isNaN(minValue) && !isNaN(maxValue)) {
            let sliderValue = [minValue, maxValue]
            first_order_discount_slider.bootstrapSlider('setValue', sliderValue.map(Number))
        }
    });

    $('.btn-helper').click(function (e) {
        e.preventDefault();
    });

    $('#pinterest_accounts_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#pinterest_accounts_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });


    $('#btn-connect-google-accounts').click(function (e) {
        $(e.target).prop('disabled', true);
        var selected_accounts = $('#google_accounts_table').DataTable().rows('.selected').data().toArray()
        if (!selected_accounts || selected_accounts.length == 0) {
            ToastCustom.fireAlertToast('No ad account selected. Please select an account from the list.');
            $(e.target).prop('disabled', false);
            return;
        }
        $.post({
            url: '/cost/select-google-accounts',
            data: {
                accounts: selected_accounts
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });
    $('#btn-connect-pinterest-accounts').click(function (e) {
        $(e.target).prop('disabled', true);
        var selected_accounts = $('#pinterest_accounts_table').DataTable().rows('.selected').data().toArray()
        if (!selected_accounts || selected_accounts.length == 0) {
            ToastCustom.fireAlertToast('No ad account selected. Please select an account from the list.');
            $(e.target).prop('disabled', false);
            return;
        }
        $.post({
            url: '/cost/select-pinterest-accounts',
            data: {
                accounts: selected_accounts
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });

    $('#btn-connect-google-analytics-profiles').click(function (e) {
        $(e.target).prop('disabled', true);
        $.post({
            url: '/cost/select-google-analytics-profiles',
            data: {
                profiles: $('#google_analytics_profiles_table').DataTable().rows('.selected').data().toArray()
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });

    $('#google_analytics_profiles_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'single',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#google_analytics_profiles_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    $('#google_accounts_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#google_accounts_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    $('#btn-connect-bing-accounts').click(function (e) {
        $(e.target).prop('disabled', true);
        $.post({
            url: '/cost/select-bing-accounts',
            data: {
                accounts: $('#bing_accounts_table').DataTable().rows('.selected').data().toArray()
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });

    $('#bing_accounts_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#bing_accounts_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    $('#btn-connect-snapchat-accounts').click(function (e) {
        $(e.target).prop('disabled', true);
        $.post({
            url: '/cost/select-snapchat-accounts',
            data: {
                accounts: $('#snapchat_accounts_table').DataTable().rows('.selected').data().toArray()
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });

    $('#btn-connect-tiktok-accounts').click(function (e) {
        $(e.target).prop('disabled', true);
        $.post({
            url: '/cost/select-tiktok-accounts',
            data: {
                accounts: $('#tiktok_accounts_table').DataTable().rows('.selected').data().toArray()
            }
        }).always(function () {
            $(e.target).prop('disabled', false);
        });
    });

    $('#snapchat_accounts_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#snapchat_accounts_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    $('#tiktok_accounts_table').DataTable({
        "bLengthChange": false,
        "info": false,
        "paging": false,
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi',
            selector: 'td:first-child'
        },
        order: [[1, 'asc']]
    });

    $('#tiktok_accounts_table tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    $('#add-continue-country-shipping-cost').click(function () {
        $.post({
            url: '/cost/shipping-cost-create',
            data: {
                country: $('select[id=shipping-country-select]').val(),
                cost: $('input[id=shipping-country-cost]').val(),
            }
        })
    });

    $('#add-close-country-shipping-cost').click(function () {
        $.post({
            url: '/cost/shipping-cost-create',
            data: {
                country: $('select[id=shipping-country-select]').val(),
                cost: $('input[id=shipping-country-cost]').val(),
            }
        })
    });


    $('.report-block').on('refresh_chart', function () {
        load_chart.configChart();
    });

    $('.report-block').on('refresh_hidden_chart', function () {
        load_chart.dashboardAreaChart();
    });


    $("#personal-requests-table").DataTable(
        {
            "searching": true,
            "bLengthChange": false,
        }
    );

    $(".report-table").DataTable(
        {
            "searching": false,
            "bLengthChange": false,
        }
    );

    $(".analysis-report-table").DataTable(
        {
            "searching": false,
            "bLengthChange": false,
            "paging": false,
            "sorting": true,
            "info": false,
        }
    );


    if ($("#chart-container").length) {
        load_chart.configChart();
    }

    $('#compare-date-btn').click(function (e) {
        e.preventDefault();
        $('#compare-date-btn').hide();
        $('#singledate-compare-wrapper').show();
        $('.form-dateend').click();
    });

    $('.btn-disablecomparetime').on('click', function (e) {
        e.preventDefault();
        $('#compare-date-btn').show();

        $('#singledate-compare-wrapper').hide();

        var data = get_filters_and_options_data();
        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data
        })

    });


    $('input:radio[data-controller="dashboard"], input:radio[data-controller="clickhouse_dashboard"], input:radio[data-controller="consolidated_dashboard"]').change(function () {
        const timeframe = $(this).attr('data-timeframe');
        const dashboard = $("#daterange").data('dashboard');

        $.post({
            url: '/' + $(this).attr('data-controller') + '/refresh-report',
            data: {
                timeframe_dashboard: timeframe,
                dashboard: dashboard
            },
            success: function () {
                $('.report-block').unblock();
            }
        })

        $(this).parent('.btn').addClass('active');
    });

    $('input:radio[data-controller="income_statement"], input:radio[data-controller="consolidated_income_statement"]').change(function () {
        const data = get_filters_and_options_data();
        data["timeframe_dashboard"] = $(this).attr('data-timeframe');
        data["dashboard"] = $("#daterange").data('dashboard');
        data["income_statement_view"] = $('.js-income-statement-tab.active').data('tab-target');
        data["income_statement_show_comparison"] = $('input[id=income_statement_show_comparison]').is(':checked')
        data["show_line_graph"] = $('#line-graph-icon').hasClass('active');

        $.post({
            url: '/' + $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                skeleton_helper.unblockControls();
            }
        })

        skeleton_helper.blockControls();
        skeleton_helper.injectSkeletons();
        $(this).parent('.btn').addClass('active');
    });

    $('select[id=dashboard_compare_to]').on('change', refresh_report)

    function refresh_report(e) {
        // disable change event to avoid sending a multiple requests
        $('select[id=dashboard_compare_to]').off('change')
        var data = get_filters_and_options_data();
        $.post({
            url: '/' + $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();
                $(this).on('change', refresh_report)
            }
        });
        block($('.report-block'))
    }

    $('select[id=income_statement_dashboard_compare_to]').on('change', function () {
        const data = get_filters_and_options_data();
        data["dashboard_compare_to"] = $(this).val();
        data["timeframe_dashboard"] = $(this).attr('data-timeframe');
        data["dashboard"] = $("#daterange").data('dashboard');
        data["income_statement_view"] = $('.js-income-statement-tab.active').data('tab-target');
        data["income_statement_show_comparison"] = $('input[id=income_statement_show_comparison]').is(':checked')
        data["show_line_graph"] = $('#line-graph-icon').hasClass('active');

        $.post({
            url: '/' + $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                skeleton_helper.unblockControls();
            }
        });

        skeleton_helper.blockControls();
        skeleton_helper.injectSkeletons();
    })

    $('input:radio[data-controller="clickhouse_predictive_lifetime"], select[id=lifetime_timeframe_select]').change(function () {

        const compareCohortsCheckbox = $('input[name="compare_cohorts"]');
        const compareCohortsChecked = compareCohortsCheckbox.is(':checked');

        if (compareCohortsChecked) {
            lifetime_compare_cohorts.triggerChangeAllCohortSelects();
            compareCohortsCheckbox.attr('data-timeperiod-or-timeframe-updated', true);
        } else {
            const form = document.querySelector('#clickhouse_predictive_lifetime_form, #ltv_breakdown_form')

            lifetime_value.updatePredictiveAndCACFormInputs(form);

            form.requestSubmit()

            const turboFrameIdentifier = form.dataset.turboFrame
            skeleton_helper.blockControls();
            skeleton_helper.injectSkeletons([turboFrameIdentifier]);
            $(this).parent('.btn').addClass('active');
        }
    });

    $('input[name="compare_cohorts"]').change(function () {
        let isChecked = $(this).is(':checked');

        if (isChecked) {
            $("#lifetime_select option[value='customers_who_purchased_x_times']").attr('disabled', 'disabled');
            $("#lifetime_select option[value='cac_sales_per_customer']").attr('disabled', 'disabled');
            $("#lifetime_select option[value='cac_sales_margin_per_customer']").attr('disabled', 'disabled');
            $("#lifetime_select option[value='contribution_margin_per_customer']").attr('disabled', 'disabled');
            $('#export-btn, .btn-filters').prop("disabled", true).addClass('disabled');

            $('#compare-cohorts, #compare-cohorts svg').addClass('checked');

            $('.js-compare-cohorts-section').show();
            $('.js-original-ltv-section').hide();

            lifetime_compare_cohorts.showCompareCohortsPromptIfTableEmpty();
        } else {
            $("#lifetime_select option").removeAttr('disabled');

            $('#compare-cohorts, #compare-cohorts svg').removeClass('checked');

            // Refresh classic LTV report if timeperiod or timeframe has been updated
            const shouldRefreshReport = $(this).attr('data-timeperiod-or-timeframe-updated')
            if (shouldRefreshReport) {
                $('select[id=lifetime_timeframe_select]').trigger('change');
            } else {
                $('#export-btn, .btn-filters').prop("disabled", false).removeClass('disabled');
                $('.js-compare-cohorts-section, .compare-cohorts-initial').hide();
                $('.js-original-ltv-section, .compare-cohorts-table').show();
            }
        }

        $('#lifetime_compare_cohorts_select').toggleClass('border-outline');
        $('#compare-cohorts').toggleClass('border-outline');

        $(this).parent('.btn').addClass('active');
    });

    $('select[id="lifetime_compare_cohorts_select"]').change(function () {
        const isCompCohortsChecked = $('input[name="compare_cohorts"]').is(':checked');
        if (isCompCohortsChecked) {
            $('.js-cohort-select-outer-container').remove();
            $('.tbl--ltv-row').remove();
            $('.compare-cohorts-initial').show();
        }

        if ($(this).val() === '') {
            $('button.add-another-cohort-link').hide();
            $('.button-hidden-text').show();
            $('.button-present-text').hide();
        } else {
            $('button.add-another-cohort-link').show();
            $('.button-hidden-text').hide();
            $('.button-present-text').show();
        }

        // Hide and clear the compare cohorts table
        const compareCohortsTable = $('.compare-cohorts-table');
        compareCohortsTable.hide();
        compareCohortsTable.find('tbody').html('');
    });

    $('input:radio[data-controller="subscriptions_report"]').change(function () {
        var data = get_filters_and_options_data();
        data["timeframe"] = $(this).attr('data-timeframe');

        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();
                skeleton_helper.unblockControls();
            }
        })

        block($('.report-block'));
        skeleton_helper.blockControls();
    });

    $('input:radio[data-controller="subscriptions_dashboard"]').change(function () {
        var data = get_filters_and_options_data();
        data["compare_timeframe"] = $(this).attr('data-compare-timeframe');

        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();
            }
        })

        block($('.report-block'))
    });

    $('#subscription-dashboard-legend-badge--comparison-period').click(function () {
        $("div[id^='subscription-chart-container-']").each(function (index, value) {
            var chart = $('#' + value.id).highcharts()
            var prev_series = chart.series[1]
            if (prev_series.visible) {
                prev_series.hide();
            } else {
                prev_series.show();
            }
        });
    });

    $('input:radio[data-controller="lifecycle"]').change(function () {
        var data = get_filters_and_options_data();
        data["timeframe_lifecycle"] = $(this).attr('data-timeframe');

        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();

            }
        })

        block($('.report-block'))
    });

    var filter_params = {
        width: '100%',
        multiple: true,
        placeholder: "All countries",
        allowClear: true,
        closeOnSelect: false,
        dataType: 'json',
        ajax: {
            url: '/filters?filter_type=country',
            data: function (params) {
                var query = {
                    search: params.term,
                    page: params.page || 1
                };

                // Query parameters will be ?search=[term]&page=[page]
                return query;
            }
        },

    };

    filter_params["placeholder"] = "All countries";
    filter_params["ajax"]["url"] = "/filters?filter_type=country_filter";
    $('select[id=country_filter]').select2(filter_params);

    filter_params["placeholder"] = "All states";
    filter_params["ajax"]["url"] = "/filters?filter_type=state_filter";
    $('select[id=state_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=customer_tags_filter";
    $('select[id=customer_tags_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any segments";
    filter_params["ajax"]["url"] = "/filters?filter_type=klaviyo_segments";
    $('select[id=klaviyo_segments_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any lists";
    filter_params["ajax"]["url"] = "/filters?filter_type=klaviyo_lists";
    $('select[id=klaviyo_lists_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any campaigns";
    filter_params["ajax"]["url"] = "/filters?filter_type=klaviyo_campaigns";
    $('select[id=klaviyo_campaigns_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=customer_tags_exclude_filter";
    $('select[id=customer_tags_exclude_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=discount_codes_filter";
    $('select[id=discount_codes_filter]').select2(filter_params);
    filter_params["ajax"]["url"] = "/filters?filter_type=order_tags_filter";
    $('select[id=order_tags_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=order_tags_exclude_filter";
    $('select[id=order_tags_exclude_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_prefilter_order_tags";
    $('select[id=shop_settings_prefilter_order_tags]').select2(filter_params);

    var selected_prefilter_order_tags = $('#selected_prefilter_order_tags').val();
    if (selected_prefilter_order_tags) {
        $('select[id=shop_settings_prefilter_order_tags]').val(JSON.parse(selected_prefilter_order_tags));
        $('select[id=shop_settings_prefilter_order_tags]').trigger('change');
    }

    filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_prefilter_contains_order_tags";
    $('select[id=shop_settings_prefilter_contains_order_tags]').select2(filter_params);

    var selected_prefilter_order_tags = $('#selected_prefilter_contains_order_tags').val();
    if (selected_prefilter_order_tags) {
        $('select[id=shop_settings_prefilter_contains_order_tags]').val(JSON.parse(selected_prefilter_order_tags));
        $('select[id=shop_settings_prefilter_contains_order_tags]').trigger('change');
    }

    filter_params["placeholder"] = "Any channels";
    filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_prefilter_sales_channel";
    $('select[id=shop_settings_prefilter_sales_channel]').select2(filter_params);

    var selected_prefilter_order_tags = $('#selected_prefilter_sales_channel').val();
    if (selected_prefilter_order_tags) {
        $('select[id=shop_settings_prefilter_sales_channel]').val(JSON.parse(selected_prefilter_order_tags));
        $('select[id=shop_settings_prefilter_sales_channel]').trigger('change');
    }

    filter_params["placeholder"] = "Any channels";
    filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_prefilter_app_sales_channel";
    $('select[id=shop_settings_prefilter_app_sales_channel]').select2(filter_params);

    var selected_prefilter_order_tags = $('#selected_prefilter_app_sales_channel').val();
    if (selected_prefilter_order_tags) {
        $('select[id=shop_settings_prefilter_app_sales_channel]').val(JSON.parse(selected_prefilter_order_tags));
        $('select[id=shop_settings_prefilter_app_sales_channel]').trigger('change');
    }

    filter_params["placeholder"] = "Any tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_prefilter_customer_tags";
    $('select[id=shop_settings_prefilter_customer_tags]').select2(filter_params);

    var selected_prefilter_order_tags = $('#selected_prefilter_customer_tags').val();
    if (selected_prefilter_order_tags) {
        $('select[id=shop_settings_prefilter_customer_tags]').val(JSON.parse(selected_prefilter_order_tags));
        $('select[id=shop_settings_prefilter_customer_tags]').trigger('change');
    }

    let currency_filter_params = Object.assign({}, filter_params);
    currency_filter_params["placeholder"] = "Select currency";
    currency_filter_params["multiple"] = false;
    currency_filter_params["allowClear"] = false;
    currency_filter_params["ajax"]["url"] = "/filters?filter_type=shop_settings_shipstation_currency";
    $('select[id=shop_settings_shipstation_currency]').select2(currency_filter_params);
    $('select[id=base_currency]').select2(currency_filter_params);

    var selected_shipstation_currency = $('#selected_shipstation_currency').val();
    if (selected_shipstation_currency) {
        $('select[id=shop_settings_shipstation_currency]').val(selected_shipstation_currency);
        $('select[id=shop_settings_shipstation_currency]').trigger('change');
    }


    filter_params["placeholder"] = "All products";
    filter_params["ajax"]["url"] = "/filters?filter_type=product_filter";
    $('select[id=product_filter]').select2(filter_params);

    filter_params["placeholder"] = "All product tags";
    filter_params["ajax"]["url"] = "/filters?filter_type=product_tag_filter";
    $('select[id=product_tag_filter]').select2(filter_params);

    filter_params["placeholder"] = "All ASINs";
    filter_params["ajax"]["url"] = "/filters?filter_type=asin_filter";
    $('select[id=asin_filter]').select2(filter_params);

    filter_params["placeholder"] = "All SKUs";
    filter_params["ajax"]["url"] = "/filters?filter_type=sku_filter";
    $('select[id=sku_filter]').select2(filter_params);

    let journey_by_select = $('#journey_by_select').val()
    if (journey_by_select) {
        let journey_by_filter_params = {
            width: '100%', multiple: false,
            placeholder: "All", allowClear: true,
            closeOnSelect: false, dropdownAutoWidth: true,
            dataType: 'json',
            ajax: {
                url: `/filters?filter_type=${journey_by_select}_filter`,
                data: function (params) {
                    return {search: params.term, page: params.page || 1}
                }
            },
        };
    }

    filter_params["placeholder"] = "All collections";
    filter_params["ajax"]["url"] = "/filters?filter_type=collection_filter";
    $('select[id=collection_filter]').select2(filter_params);

    filter_params["placeholder"] = "All product types";
    filter_params["ajax"]["url"] = "/filters?filter_type=product_type_filter";
    $('select[id=product_type_filter]').select2(filter_params);

    filter_params["placeholder"] = "All vendors";
    filter_params["ajax"]["url"] = "/filters?filter_type=vendor_filter";
    $('select[id=vendor_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any sales channel";
    filter_params["ajax"]["url"] = "/filters?filter_type=sales_channel_filter";
    $('select[id=sales_channel_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any sales channel";
    filter_params["ajax"]["url"] = "/filters?filter_type=sales_channel_exclude_filter";
    $('select[id=sales_channel_exclude_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" source";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_source_filter";
    $('select[id=first_utm_source_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" medium";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_medium_filter";
    $('select[id=first_utm_medium_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" term";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_term_filter";
    $('select[id=first_utm_term_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" content";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_content_filter";
    $('select[id=first_utm_content_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" content";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_channel_filter";
    $('select[id=first_utm_channel_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" content";
    filter_params["ajax"]["url"] = "/filters?filter_type=first_utm_campaign_filter";
    $('select[id=first_utm_campaign_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"first touch\" marketing channel";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_channel_filter";
    $('select[id=last_utm_channel_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"last touch\" source";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_source_filter";
    $('select[id=last_utm_source_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"last touch\" medium";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_medium_filter";
    $('select[id=last_utm_medium_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"last touch\" term";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_term_filter";
    $('select[id=last_utm_term_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"last touch\" content";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_content_filter";
    $('select[id=last_utm_content_filter]').select2(filter_params);

    filter_params["placeholder"] = "Any \"last touch\" content";
    filter_params["ajax"]["url"] = "/filters?filter_type=last_utm_campaign_filter";
    $('select[id=last_utm_campaign_filter]').select2(filter_params);

    $('select[id=benchmark_comparison_metric_category]').select2({
        placeholder: "Select a category"
    });

    $('.js-export-ltv-btn').click(function (e) {
        e.preventDefault();

        // Pull current filters & options
        var data = get_filters_and_options_data();
        // Pull current metric & format values
        data['ltv_show_format'] = $("#format-toggle .active input").attr('data-ltv_show_format');
        data['metric'] = $('select#lifetime_select').val();
        data['show_predictive_ltv'] = $("#togglePredictiveLTV").is(":checked");

        const urlParams = $.param(data);
        const exportCSVURL = $(this).attr('href') + '&' + urlParams;

        // Re-point the page to the CSV location
        document.location.href = exportCSVURL;
        return false;
    });

    $('input[id=shop_settings_use_shopify_shipping_costs]').change(function (e) {
        $.post({
            url: '/settings/update_shopify_shipping_costs',
            data: {use_shopify_shipping_costs: $(this).is(':checked')}
        })
    });

    $('input[id=unlock_benchmarks]').change(function (e) {
        const unlock_benchmarks = $('input[id=unlock_benchmarks]').is(':checked');
        const checkboxLabel = $(this).closest('label');

        if (unlock_benchmarks) {
            checkboxLabel.addClass('checked');
        } else {
            checkboxLabel.removeClass('checked');
        }

        $.post({
            url: '/settings/update_disable_benchmarks',
            data: {unlock_benchmarks: unlock_benchmarks}
        })
    });

    $('input[id=shop_settings_use_shipping_charged_for_shipping_costs]').change(function (e) {
        $.post({
            url: '/settings/update_shipping_charged_for_shipping_costs',
            data: {use_shipping_charged_for_shipping_costs: $(this).is(':checked')}
        })
    });

    $('select[id=lifecycle_select]').change(function (e) {

        var dataMetric = e.target.value;
        var tableDataBlocks = $('.js-table-data');

        tableDataBlocks.each(function () {
            if ($(this).hasClass("tbl--shade")) {
                $(this).removeClass(function (index, className) {
                    return (className.match(/tbl--shade[\d*]/));
                });
            } else {
                $(this).removeClass("text-success text-danger");
            }
            var saleData = $(this).data(dataMetric);
            $(this).addClass(saleData["class"]);
            $(this).html(`<div>${saleData["value"]} </div>`);
        });

    });

    $('select[id=journey_by_select]').change(function (e) {
        var journey_by_option_value = e.target.value;
        var url = `/filters?filter_type=${journey_by_option_value}_filter`
        var filter_params = {
            width: '100%',
            closeOnSelect: false,
            allowClear: true,
            dropdownAutoWidth: true,
            placeholder: "All",
            dataType: 'json',
            ajax: {
                url: url,
                data: function (params) {
                    var query = {
                        search: params.term,
                        page: params.page || 1
                    };

                    // Query parameters will be ?search=[term]&page=[page]
                    return query;
                }
            },

        };

        const form = document.getElementById('product_journey_form')
        form.requestSubmit()

        const turboFrameIdentifier = form.dataset.turboFrame
        skeleton_helper.blockControls();
        skeleton_helper.injectSkeletons([turboFrameIdentifier]);
    });

    $('input[id=level_of_details_slider]').on('slideStop', function (e) {
        const form = document.getElementById('product_journey_form')
        form.requestSubmit()

        const turboFrameIdentifier = form.dataset.turboFrame
        skeleton_helper.blockControls();
        skeleton_helper.injectSkeletons([turboFrameIdentifier]);
    });

    var setUpSubscriptionReportPicker = () => {
        const datePickerIdentifier = 'subscription_report_daterange'
        const yearSteps = 6

        function cb_ltv(start, end, datePickerIdentifier) {
            $(`#${datePickerIdentifier} span`).html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        var dateRangePickerElement = $(`#${datePickerIdentifier}`);
        var startYear = moment().format('YYYY');
        var start_ltv = dateRangePickerElement.attr('data-date-start');
        var end_ltv = dateRangePickerElement.attr('data-date-end');
        var rangesHash = {};
        var timeZone = dateRangePickerElement.attr('data-shop-timezone');

        if (timeZone) {
            rangesHash["Last 6 months"] = [moment().subtract(6, 'months').startOf('month'), moment().tz(timeZone).subtract(1, 'days')];
        } else {
            rangesHash["Last 6 months"] = [moment().subtract(6, 'months').startOf('month'), moment().subtract(1, 'days')];
        }

        for (var i = 0; i < yearSteps; i++) {
            var year = startYear - i
            if (timeZone) {
                rangesHash[`Since ${year}`] = [moment([year]).startOf('year'), moment().tz(timeZone).subtract(1, 'days')];
            } else {
                rangesHash[`Since ${year}`] = [moment([year]).startOf('year'), moment().subtract(1, 'days')];
            }
        }

        function getYesterdayDate(timezone = null) {
            var date = new Date();
            if (timezone) {
                return moment(date).tz(timezone).subtract(1, 'day').format("MM/DD/YYYY")
            } else {
                return moment(date).subtract(1, 'day').format("MM/DD/YYYY")
            }
        }

        var dateRangePickerConfig = {
            startDate: start_ltv,
            endDate: end_ltv,
            alwaysShowCalendars: true,
            autoApply: true,
            showDropdowns: true,
            linkedCalendars: false,
            ranges: rangesHash
        }

        dateRangePickerConfig["maxDate"] = new Date() // Max date = today

        dateRangePickerElement.daterangepicker(dateRangePickerConfig);

        if (timeZone) {
            cb_ltv(moment(start_ltv, "MM/DD/YYYY").tz(timeZone), moment(end_ltv, "MM/DD/YYYY").tz(timeZone));
        } else {
            cb_ltv(moment(start_ltv, "MM/DD/YYYY"), moment(end_ltv, "MM/DD/YYYY"));
        }

        dateRangePickerElement.on('apply.daterangepicker', function (ev, picker) {

            var data = get_filters_and_options_data();
            data["date_from"] = picker.startDate.format('YYYY-MM-DD')
            data["date_to"] = picker.endDate.format('YYYY-MM-DD')

            $.post({
                url: '/' + $(this).attr('data-controller') + '/refresh-report',
                data: data,
                success: function () {
                    skeleton_helper.unblockControls();
                    lifetime_compare_cohorts.updateControlsStates();
                }
            })

            skeleton_helper.blockControls();
            skeleton_helper.injectSkeletons();

            cb_ltv(moment(picker.startDate.format('YYYY-MM-DD')), moment(picker.endDate.format('YYYY-MM-DD')));

        });

        dateRangePickerElement.on("keydown", function (e) {
            if (e.which == 13) {
                $('.daterangepicker button.applyBtn').click();
            }
        });
    };

    setUpSubscriptionReportPicker();

    // single date picker
    const singleDatePicker = $('input[name="singledate"]');
    singleDatePicker.daterangepicker({
        singleDatePicker: true,
        autoApply: true
    });


    singleDatePicker.on('apply.daterangepicker', function (ev, picker) {

        var data = get_filters_and_options_data();

        data["date_to"] = picker.endDate.format('YYYY-MM-DD')

        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();

            }
        })

        block($('.report-block'))

    });

    singleDatePicker.on("keydown", function (e) {
        if (e.which == 13) {
            $('.daterangepicker button.applyBtn').click();
        }
    });

    // compare date picker
    const compareDatePicker = $('input[name="singledate-compare"]');
    compareDatePicker.daterangepicker({
        singleDatePicker: true,
        autoApply: true
    });

    var compare_end_period = compareDatePicker.attr('data-date-end');

    function cb_single_compare(end) {
        $('#singledate-compare span').html(end.format('MMMM D, YYYY'));
    }

    cb_single_compare(moment(compare_end_period, "MM/DD/YYYY"));

    compareDatePicker.on('apply.daterangepicker', function (ev, picker) {

        var data = get_filters_and_options_data();

        data["compare_date_to"] = picker.endDate.format('YYYY-MM-DD');

        $.post({
            url: $(this).attr('data-controller') + '/refresh-report',
            data: data,
            success: function () {
                $('.report-block').unblock();

            }
        })

        block($('.report-block'))

        cb_single_compare(moment(picker.endDate.format('YYYY-MM-DD')));

    });

    compareDatePicker.on("keydown", function (e) {
        if (e.which == 13) {
            $('.daterangepicker button.applyBtn').click();
        }
    });


    // set up Order details
    setUpDateRangePicker('order_details_daterange', false, true, {});

    // set up Product Metrics
    setUpDateRangePicker('product_metrics_daterange', true, true, {});

    $('select[id=product_metrics_select]').change(function () {

        const data = $('table#products-table').DataTable().ajax.params();
        data['metric_product_metrics'] = $(this).val();

        $.post({
            url: "/products/refresh-report",
            data: data,
            success: function () {
                $('.report-block').unblock();
            }
        })
    });

    $('#product-metrics-export-btn').click(function (e) {
        e.preventDefault();

        const dataTable = $('table#products-table').DataTable()
        const params = $.param(dataTable.ajax.params())
        $(this).attr("href", "/products/export.csv?" + params);

        const totalEntries = dataTable.page.info().recordsDisplay
        const confirmResult = confirm(`Are you sure you want to download a CSV file with ${totalEntries} rows?`);

        if (confirmResult) {
            window.location = $(this).attr('href');
        }
    });

    // set up ppc report
    setUpDateRangePicker('ppc_daterange', true, true, {});

    const selectableColumnsContainer = $(".selectable-columns-btn-container")
    const selectableColumnsToggleBtn = $(".selectable-columns-btn")
    const selectColumnsApplyBtn = $('.selectable-columns-container button[name="apply"]')

    selectableColumnsToggleBtn.on('click', function (e) {
        selectableColumnsContainer.toggleClass('active')
    })

    selectColumnsApplyBtn.on('click', () => {
        const checkedColumns = Array.from($('.selectable-columns-container tr.checked'))
        const selectedColumns = checkedColumns.map(elem => elem.getAttribute('data-ppc-column-value'))

        const data = $('table#ppc-table').DataTable().ajax.params();
        data['selected_columns'] = selectedColumns
        $.post({
            url: "/ppc/refresh-report",
            data: data,
            success: function () {
                $('.report-block').unblock();
            }
        })
    });


    $('#ppc-export-btn').click(function (e) {
        e.preventDefault();

        const dataTable = $('table#ppc-table').DataTable()
        const params = $.param(dataTable.ajax.params())
        $(this).attr("href", "/ppc/export.csv?" + params);

        const totalEntries = dataTable.page.info().recordsDisplay
        const confirmResult = confirm(`Are you sure you want to download a CSV file with ${totalEntries} rows?`);

        if (confirmResult) {
            window.location = $(this).attr('href');
        }
    });

    $('select[id=ppc_group_by]').change(function () {

        const data = $('table#ppc-table').DataTable().ajax.params();
        data['group_by'] = $(this).val();

        $.post({
            url: "/ppc/refresh-report",
            data: data,
            success: function () {
                $('.report-block').unblock();
            }
        })
    });

    $('select[id=ppc_status]').change(function () {

        const data = $('table#ppc-table').DataTable().ajax.params();
        data['ppc_status'] = $(this).val();

        $.post({
            url: "/ppc/refresh-report",
            data: data,
            success: function () {
                $('.report-block').unblock();
            }
        })
    });

    // set up income statement
    var timeZone = $("#income_statement_daterange").attr('data-shop-timezone');
    var incomeStatementRanges = {}
    if (timeZone) {
        incomeStatementRanges = {
            'Today': [moment().tz(timeZone), moment().tz(timeZone)],
            'Yesterday': [moment().tz(timeZone).subtract(1, 'days'), moment().tz(timeZone).subtract(1, 'days')],
            'Last 7 Days': [moment().tz(timeZone).subtract(7, 'days'), moment().tz(timeZone).subtract(1, 'days')],
            'Last 30 Days': [moment().tz(timeZone).subtract(30, 'days'), moment().tz(timeZone).subtract(1, 'days')],
            'Last 365 Days': [moment().tz(timeZone).subtract(365, 'days'), moment().tz(timeZone)],
            'This Month': [moment().tz(timeZone).startOf('month'), moment()],
            'Last Month': [moment().tz(timeZone).subtract(1, 'month').startOf('month'), moment().tz(timeZone).subtract(1, 'month').endOf('month')],
            'Last 6 Months': [moment().tz(timeZone).subtract(6, 'month').startOf('month'), moment().tz(timeZone)],
            'This Quarter': [moment().tz(timeZone).startOf('quarter'), moment().tz(timeZone)],
            'Last Quarter': [moment().tz(timeZone).subtract(1, 'Q').startOf('quarter'), moment().tz(timeZone).startOf('quarter').subtract(1, 'day')],
            'This Year': [moment().tz(timeZone).startOf('year'), moment().tz(timeZone)],
            'Last Year': [moment().tz(timeZone).subtract(1, 'year').startOf('year'), moment().tz(timeZone).subtract(1, 'year').endOf('year')]
        }
    } else {
        incomeStatementRanges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
            'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
            'Last 365 Days': [moment().subtract(365, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment()],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment()],
            'This Quarter': [moment().startOf('quarter'), moment()],
            'Last Quarter': [moment().subtract(1, 'Q').startOf('quarter'), moment().startOf('quarter').subtract(1, 'day')],
            'This Year': [moment().startOf('year'), moment()],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        }
    }

    setUpDateRangePicker('income_statement_daterange', false, true, incomeStatementRanges);
    setUpDateRangePicker('income_statement_compare_to_daterange', false, true, incomeStatementRanges);

    effects.effects();
    lifetime_value.init();
    onboarding_form.init();
    left_main_menu.init();
    scroll_fade_overlay.init();

    // Show and hide filters inputs on filter group dropdown click
    const filtersGroupToggles = $('.filters--group-toggle')
    if (filtersGroupToggles.length > 0) {
        filtersGroupToggles.on('click', function (e) {
            e.preventDefault();
            $(this).parent('.filters--group-container').toggleClass('open');
        });
    }

    if ($('.js-import-csv-button').length) {
        usecsv_importer.initCSVImporter();
    }

    // For links to pages that we don't cache (like the Dashboard), always show the spinner
    $('.force-show-turbolinks-spinner').click(function () {
        turbolinksHelper.showTurbolinksSpinner();
    });

    $('.settings-tab').click(function (e) {
        if (
            ($(this).attr('target') === '_blank') ||
            (e.ctrlKey ||
                e.shiftKey ||
                e.metaKey || // apple
                (e.button && e.button == 1)) // middle click, >IE9 + everyone else
        ) {
            return;
        }

        $('.horizontal-settings-main-content').html("<div class='turbolinks-spinner-container'><span class='turbolinks-spinner'></span></div>");
    });

    freePlanDatepicker.initDisabledRanges()

    // Initial onboarding JS
    const getStartedSubmitBtn = document.getElementById('get_started_submit_btn')
    if (getStartedSubmitBtn) {
        const checkboxes = document.querySelectorAll("input[type=radio][name='onboarding_record[desired_outcome]']");
        checkboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', function () {
                getStartedSubmitBtn.removeAttribute('disabled')
            })
        });
    }
    const choosePlanLinks = document.querySelectorAll('.js-choose-plan-link')
    if (choosePlanLinks.length > 0) {
        choosePlanLinks.forEach(function (choosePlanLink) {
            choosePlanLink.addEventListener('click', function () {
                const closestPlanCard = choosePlanLink.closest('.plan-card');
                closestPlanCard.classList.add('selected_plan')
                document.querySelector('.plans-container').classList.add('plan_is_selected')
            })
        });
    }
}

var setUpDateRangePicker = (datePickerIdentifier, setmaxDateYesterday = false, hasSkeleton = false, ranges = {}) => {
    var dateRangePickerElement = $(`#${datePickerIdentifier}`);
    var start = dateRangePickerElement.attr('data-date-start');

    var end = dateRangePickerElement.attr('data-date-end');
    var timeZone = dateRangePickerElement.attr('data-shop-timezone');

    function cb(start, end) {
        $('#daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    function getYesterdayDate(timezone = null) {
        var date = new Date();
        if (timezone) {
            return moment(date).tz(timezone).subtract(1, 'day').format("MM/DD/YYYY")
        } else {
            return moment(date).subtract(1, 'day').format("MM/DD/YYYY")
        }
    }

    // If ranges aren't passed as an argument, use default ranges
    if ($.isEmptyObject(ranges)) {
        if (timeZone) {
            ranges = {
                'Today': [moment().tz(timeZone), moment().tz(timeZone)],
                'Yesterday': [moment().tz(timeZone).subtract(1, 'days'), moment().tz(timeZone).subtract(1, 'days')],
                'Last 7 Days': [moment().tz(timeZone).subtract(6, 'days'), moment().tz(timeZone)],
                'Last 30 Days': [moment().tz(timeZone).subtract(29, 'days'), moment().tz(timeZone)],
                'Last 180 Days': [moment().tz(timeZone).subtract(180, 'days'), moment().tz(timeZone)],
                'Last 365 Days': [moment().tz(timeZone).subtract(365, 'days'), moment().tz(timeZone)],
                'This Month': [moment().tz(timeZone).startOf('month'), moment().tz(timeZone).subtract(1, 'day')],
                'Last Month': [moment().tz(timeZone).subtract(1, 'month').startOf('month'), moment().tz(timeZone).subtract(1, 'month').endOf('month')]
            }
        } else {
            ranges = {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Last 180 Days': [moment().subtract(180, 'days'), moment()],
                'Last 365 Days': [moment().subtract(365, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().subtract(1, 'day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }
    }

    var dateRangePickerConfig = {
        startDate: start,
        endDate: end,
        alwaysShowCalendars: true,
        autoApply: true,
        showDropdowns: true,
        linkedCalendars: false,
        ranges: ranges,
    }

    const lifetimelyPlanMetaTag = document.head.querySelector('[name="lifetimely-plan"]');
    if (lifetimelyPlanMetaTag && lifetimelyPlanMetaTag.content === 'Free') {
        dateRangePickerConfig.minDate = moment().subtract(29, 'days')
    }

    if (setmaxDateYesterday) {
        dateRangePickerConfig["maxDate"] = getYesterdayDate(timeZone)
    } else {
        dateRangePickerConfig["maxDate"] = new Date() // Max date = today
    }

    dateRangePickerElement.daterangepicker(dateRangePickerConfig);
    if (timeZone) {
        cb(moment(start, "MM/DD/YYYY").tz(timeZone), moment(end, "MM/DD/YYYY").tz(timeZone));
    } else {
        cb(moment(start, "MM/DD/YYYY"), moment(end, "MM/DD/YYYY"));
    }

    dateRangePickerElement.on('apply.daterangepicker', function (ev, picker) {

        var data = get_filters_and_options_data();

        if ($(this).attr('data-compare-to-id')) {
            $(`#${$(this).attr('data-compare-to-id')}`).val("custom_period");
            data["dashboard_compare_to"] = $(`#${$(this).attr('data-compare-to-id')}`).val()
            data["dashboard_comp_date_from"] = picker.startDate.format('YYYY-MM-DD')
            data["dashboard_comp_date_to"] = picker.endDate.format('YYYY-MM-DD')

        } else {
            data["date_from"] = picker.startDate.format('YYYY-MM-DD')
            data["date_to"] = picker.endDate.format('YYYY-MM-DD')
        }


        var refreshReportRoute = '/refresh-report';
        $.post({
            url: '/' + $(this).attr('data-controller') + refreshReportRoute,
            data: data,
            success: function () {
                if (!hasSkeleton) {
                    $('.report-block').unblock();
                }
                skeleton_helper.unblockControls();
            }
        })

        if (hasSkeleton) {
            skeleton_helper.injectSkeletons();
        } else {
            block($('.report-block'))
        }

        skeleton_helper.blockControls();

        cb(moment(picker.startDate.format('YYYY-MM-DD')), moment(picker.endDate.format('YYYY-MM-DD')));
    });

    // Simulate "click" on hidden "apply" button for all datepickers when the enter key is pressed
    dateRangePickerElement.on("keydown", function (e) {
        if (e.which == 13) {
            $('.daterangepicker button.applyBtn').click();
        }
    });
}

window.load_chart = load_chart;
window.effects = effects;
window.product_cost = product_cost;
window.lifetime_value = lifetime_value;
window.income_statement = income_statement;
window.lifetime_compare_cohorts = lifetime_compare_cohorts;
window.skeleton_helper = skeleton_helper;
window.mount_turbolinks_event_listener = () => {
    setUpGlobalEventListeners();
}

window.onbeforeunload = function (e) {
    if ($('#google_accounts_table').length) {
        var selected_accounts = $('#google_accounts_table').DataTable().rows('.selected').data().toArray()
        if (!selected_accounts || selected_accounts.length == 0) {
            e.returnValue = 'No ad account selected. Please select an account from the list.';
            ToastCustom.fireAlertToast('No ad account selected. Please select an account from the list.');
        }
    }
}

document.addEventListener('turbo:frame-render', function (event) {
    // TODO - just unblock controls linked to frame
    skeleton_helper.unblockControls();

    if ($('select[id=lifetime_select]').length > 0) {
        lifetime_compare_cohorts.updateControlsStates();
    }
});

document.addEventListener('turbo:click', function (event) {
    const snapshotCacheExists = Turbo.session.view.snapshotCache.keys.includes(event.detail.url);
    // Show spinner if we don't have yet have cached preview content AND if the clicked link has 'show-turbolinks-spinner' class
    if (!snapshotCacheExists && event.target.className.includes('show-turbolinks-spinner')) {
        turbolinksHelper.showTurbolinksSpinner()
    }
});

window.skeletonCache = {}

document.addEventListener("turbo:load", () => {
    // Async - run soon but don't wait for it to begin other functions
    Promise.resolve().then(setUpGlobalEventListeners);
    Promise.resolve().then(data => setUpDateRangePicker('daterange', data));
    Promise.resolve().then(turbolinksHelper.prepareTurbolinksCachedInitialContent);
});

// Force turbolinks to fully reload pages when user navigates with browser's "Back" and "Forward" buttons
window.addEventListener('popstate', function () {
    turbolinksHelper.turbolinksReloadOnRestorationVisits();
});

$(document).ready(function () {

    $(document).on("click", '.custom-cost-toggle-button', function () {
        $.ajax({
            url: '/cost/switch_status',
            type: 'post',
            data: {
                id: $(this).attr('data-custom-cost-id'),
            },
            success: function (data) {
                let label = $(`label[for="status_${data['id']}"]`)

                if (label.html() == "Active") label.html("Stopped")
                else label.html("Active")
            }
        });
    });

    $(document).on("click", '#include_time_period', function () {
        $('#date_ranges_wrapper').toggleClass("hidden");

        let shareable_link = $('#shareable_link')
        let shareable_link_value = shareable_link.val()
        let includesAny = ["date_from", "date_to"].some(key => shareable_link_value.includes(key));
        let date_from = $('#shareable_link_date_from').val()
        let date_to = $('#shareable_link_date_to').val()
        let compare_to = $('#shareable_link_compare_to').val()

        if (includesAny) {
            shareable_link.val(shareable_link_value.split('?')[0])
        } else {
            shareable_link.val(shareable_link_value + `?date_from=${date_from}&date_to=${date_to}&compare_to=${compare_to}`)
        }
    });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
