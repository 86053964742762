import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-validate"
export default class extends Controller {

  connect() {
    const inputField = this.element.querySelector("input[name='invite_emails']")
    const submitBtn = this.element.querySelector("button[type='submit'], input[type='submit']")
    const errorElement = document.getElementById('email-errors')

    submitBtn.disabled = true

    inputField.addEventListener('input', () => {
      const emails = inputField.value.split(',').map(email => email.trim())
      const isEmpty = inputField.value.trim() === ''
      const isValid = !isEmpty && emails.every(email => this.isValidEmail(email))

      if (isValid) {
        inputField.classList.remove('has-error')
        this.clearErrors(errorElement)
        submitBtn.disabled = false
      } else {
        submitBtn.disabled = true
        if (!isEmpty) {
          inputField.classList.add('has-error')
        } else {
          inputField.classList.remove('has-error')
          this.clearErrors(errorElement)
        }
      }
    })

    inputField.addEventListener('blur', () => {
      const emails = inputField.value.split(',').map(email => email.trim())
      const isEmpty = $.isEmptyObject(inputField.value.trim())
      const isValid = !isEmpty && emails.every(email => this.isValidEmail(email))

      if (isValid) {
        inputField.classList.remove('has-error')
        this.clearErrors(errorElement)
      } else if (!isEmpty) { 
        inputField.classList.add('has-error')
        this.showErrors("Please enter valid email addresses.", errorElement)
      }
    })
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  showErrors(message, errorElement) {
    if (errorElement) {
      errorElement.textContent = message
      errorElement.classList.remove('hidden')
    }
  }

  clearErrors(errorElement) {
    if (errorElement) {
      errorElement.textContent = ""
      errorElement.classList.add('hidden')
    }
  }
}
